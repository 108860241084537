import { useState, useEffect, lazy } from "react";
import { observer } from 'mobx-react';
import { useAuth } from "./AuthContext";
import Loading from "./Components/Loading";
import { http } from "./http";
const Main = lazy(() => import('./Main'));
const Login = lazy(() => import('./Components/Login'));

interface StateItem{
  names:string[];
}
export const takeAnswer = async (
  answer: any, accessToken:string, method:string='post',url:string=(window.location.href.includes('localhost:')?'http://localhost:5243':'')+'/api/sc'
): Promise<StateItem | undefined> => {
  try {
    const result = await http<null, StateItem>({
      path: url,
      method: method,
      body: answer,
      accessToken:accessToken
    });
    if (result.ok) {
      return result.parsedBody;}
    else
      if(result.status === 401){
        console.log("result:401");
      }
     else {
      return undefined;
    }
  } catch (ex) {
    console.error(ex);
    return undefined;
  }
};

export const App = observer(() => {
  const { token, names, setAuth } = useAuth();
  useEffect(() => {
    const doIt = async () => {
      console.log('App doIt, token: '+token+' names: '+names);
      if(names === undefined){
        const result = await takeAnswer( null,'');
        if(result !== undefined)
          setAuth(undefined,result.names);
      }
      };
      doIt();
    }, [token, names]);
    return (<>
      {names!?.map((name)=>{
        return <div key={name}>{name}</div>
      })}
      {names!==undefined?<>
        {token!==''?
        <Main/>
        :
        <Login/>
        }</>
        :
        <Loading/>
      }
    </>
  );
});

export default App;
