import React,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import { AuthProvider } from './AuthContext';
import Header from './Components/Header';
import en from './translations/en.json';
import ru from './translations/ru.json';
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
//import reportWebVitals from './reportWebVitals';

setDefaultTranslations({ru, en});
setDefaultLanguage('ru');
//setLanguage(setupData.setup?.language.defaultLanguage!)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Header/>
      <Suspense fallback>
        <App/>
      </Suspense>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
